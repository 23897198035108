@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

.rdg {
  --rdg-color: #64748b !important;
  --rdg-border-color: #e2e8f0 !important;
  --rdg-summary-border-color: #fff !important;
  --rdg-background-color: #fff !important;
  --rdg-header-background-color: #f8fafc !important;
  --rdg-row-hover-background-color: #f1f5f9 !important;
  --rdg-row-selected-background-color: #f1f5f9 !important;
  --row-selected-hover-background-color: #dbeafe !important;
  --rdg-checkbox-color: #fff !important;
  --rdg-checkbox-focus-color: #fff !important;
  --rdg-checkbox-disabled-border-color: #fff !important;
  --rdg-checkbox-disabled-background-color: #fff !important;

  html,
  body {
    @apply antialiased;
    padding: 0;
    margin: 0;
  }

  * {
    box-sizing: border-box;
  }
}

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: transparent; /* or add it to the track */
}

*::-webkit-scrollbar-thumb {
  background: #94a3b8;
  border-radius: 6px;
}

.h-112 {
  height: 28rem;
}

.w-112 {
  width: 28rem;
}

.w-inherit {
  width: inherit;
}

.min-w-5xs {
  min-width: 8rem;
}

.min-w-4xs {
  min-width: 10rem;
}

.min-w-3xs {
  min-width: 12rem;
}
.min-w-2xs {
  min-width: 16rem;
}
.min-w-8xl {
  max-width: 88rem;
}

.min-h-40 {
  min-height: 10rem;
}

.min-h-60 {
  min-height: 15rem;
}

.min-h-80 {
  min-height: 20rem;
}

.max-w-36 {
  max-width: 9rem;
}

.max-w-40 {
  max-width: 10rem;
}

.max-w-3xs {
  max-width: 12rem;
}

.max-w-2xs {
  max-width: 16rem;
}

.max-w-8xl {
  max-width: 88rem;
}

.max-h-120 {
  max-height: 30rem;
}

.max-h-full {
  max-height: 100%;
}

@layer components {
  .btn {
    @apply inline-flex items-center whitespace-nowrap rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 disabled:opacity-50;
  }

  .btn-primary {
    @apply border-transparent bg-blue-600 text-white hover:bg-blue-700;
  }

  .btn-danger {
    @apply border-transparent bg-red-600 text-white hover:bg-red-700 focus:ring-red-500;
  }

  .btn-sm {
    @apply px-3 py-2 leading-4;
  }

  .btn-ghost {
    @apply border-transparent bg-transparent hover:bg-gray-100;
  }
}

.__react_component_tooltip {
  border-radius: 4px !important;
  background: #1f2937 !important;
  padding: 0.25rem 0.75rem !important;
  max-width: 20rem;
  white-space: pre-wrap;
  transition: 0 !important;
}

.__react_component_tooltip.place-right::after {
  border-right-color: #1f2937 !important;
}

div[data-popper-reference-hidden='true'] {
  opacity: 0;
  pointer-events: none;
}

.dark [type='checkbox']:checked,
.dark [type='radio']:checked {
  background-color: #3b83f6;
}

.pac-container {
  border-color: #e5e7eb;
  border-radius: 10px;
  margin-top: 6px;
}

.pac-item {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 14px;
}

.pac-item span {
  font-size: 0.75rem;
  letter-spacing: 0.1px;
}

.pac-item .pac-item-query .pac-matched span {
  font-size: 1rem;
}

.hdpi .pac-icon {
  display: none !important;
}

.hdpi.pac-logo:after {
  display: none !important;
}

.pickup-address-indicator::after {
  content: '';
  position: absolute;
  width: 2px;
  background: #a2bcf6;
  background: linear-gradient(180deg, #a2bcf6 0%, #2563eb 100%);
  top: 22px;
  height: 130%;
  border-radius: 1rem;
  margin-left: 6px;
}

.pickup-address-indicator--short::after {
  top: 30px;
  height: 24px;
  margin-left: 7px;
}

.pickup-icon {
  height: 24px;
  width: 24px;
  background-image: url('./pickup.svg');
  background-size: contain;
}

.dropoff-icon {
  height: 24px;
  width: 24px;
  background-image: url('./dropoff.svg');
  background-size: contain;
}

.trip-request-create-input {
  display: none;
}

.snow {
  height: 100px;
  top: 0;
  left: 0;
  right: 0;
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50%3B' xml:space='preserve'%3E%3Cstyle type='text/css'%3E.st1%7Bopacity:0.3%3Bfill:%233498DB%3B%7D.st3%7Bopacity:0.1%3Bfill:%23FFFFFF%3B%7D%3C/style%3E%3Ccircle class='st1' cx='5' cy='8' r='1'/%3E%3Ccircle class='st1' cx='38' cy='3' r='1'/%3E%3Ccircle class='st1' cx='12' cy='4' r='1'/%3E%3Ccircle class='st1' cx='16' cy='16' r='1'/%3E%3Ccircle class='st1' cx='47' cy='46' r='1'/%3E%3Ccircle class='st1' cx='32' cy='10' r='1'/%3E%3Ccircle class='st1' cx='3' cy='46' r='1'/%3E%3Ccircle class='st1' cx='45' cy='13' r='1'/%3E%3Ccircle class='st1' cx='10' cy='28' r='1'/%3E%3Ccircle class='st1' cx='22' cy='35' r='1'/%3E%3Ccircle class='st1' cx='3' cy='21' r='1'/%3E%3Ccircle class='st1' cx='26' cy='20' r='1'/%3E%3Ccircle class='st1' cx='30' cy='45' r='1'/%3E%3Ccircle class='st1' cx='15' cy='45' r='1'/%3E%3Ccircle class='st1' cx='34' cy='36' r='1'/%3E%3Ccircle class='st1' cx='41' cy='32' r='1'/%3E%3C/svg%3E");
  background-position: 0px 0px;
  animation: animatedBackground 10s linear infinite;
}

@keyframes animatedBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0px 300px;
  }
}

.boxdraw {
  background: rgba(56, 135, 190, 0.1);
  border: 2px solid #3887be;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}

.maplibregl-popup-content {
  padding: 0;
  border-radius: 0.5rem;
}

.maplibregl-ctrl-bottom-right {
  display: none;
}

.service-type-indicator {
  height: 1rem;
  width: 1rem;
  border-radius: 2px;
}

.service-type-indicator--pickup {
  background-color: rgba(234, 179, 8, 0.1);
  border: 2px solid #eab308;
}

.service-type-indicator--dropoff {
  background-color: rgba(2, 132, 199, 0.1);
  border: 2px solid #0284c7;
}

.service-type-indicator--both {
  background-color: rgba(22, 163, 74, 0.1);
  border: 2px solid #16a34a;
}

.service-type-indicator--none {
  background-color: rgba(156, 163, 175, 0.1);
  border: 2px solid #9ca3af;
}

.__react_component_tooltip.place-top::before {
  background-color: transparent !important;
}

.color-swatch::-webkit-color-swatch {
  border-radius: 0.5rem;
  border: 1px solid #9ca3af;
}

.input-container {
  position: relative;

  label {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    padding-left: 1.5rem;
    transition: all 0.2s ease;
  }

  input {
    &:focus,
    &:not(:placeholder-shown) {
      + label {
        font-size: 0.75rem;
        height: 2.5rem;
      }
    }
  }
}

.no-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.markdown {
  a {
    text-decoration: underline;
  }
}
